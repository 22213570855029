h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h4 {
  font-size: 0.9rem;
}

#masthead {
  background-image: url('https://www.ebi.ac.uk/web_guidelines/EBI-Framework/v1.1/images/backgrounds/training-yellow-5.jpg');
}

.dropdown-on-light-background {
  color: black !important;
}

.dropdown-on-light-background::after {
  border-color: black transparent transparent !important;
}

.dropdown-on-dark-background {
  color: white !important;
}

.cursor {
  cursor: pointer;
}

.secondary-background-important {
  background-color: #555555 !important;
}

.strikeout {
  text-decoration: line-through;
  color: #999;
}

.strikeout .column {
  text-decoration: line-through;
  color: #999;
}

.anchor-like {
  cursor: pointer;
  color: #222;
  line-height: initial;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
}

.archived {
  color: red;
  /* display: inline-flex;*/
  margin-right: 3px;
}

.archived button {
  padding-left: 3px;
  color: red;
}

/*#checkboxMapping{*/
input[type='checkbox'] {
  width: 14px;
  height: 14px;
  border: 10px solid;
  /*top: 4px;*/
  position: relative;
  cursor: pointer;
}

input[type='checkbox'] + span {
  font-size: 16px;
  /*display: contents;*/
  padding: 3px;
}

input[type='checkbox']:checked {
  position: relative;
}

input[type='checkbox']:checked + span {
  border: 2px solid green;
  padding: 3px;
}

input[type='checkbox']:checked + div > .attribute_label {
  border: 2px solid green;
  padding: 3px;
}

.submit_fixed {
  padding: 10px;
  /* background: #f1f1f1; */
  text-align: center;
  bottom: 0px;
}

.competency_list {
  /*border: 1px dashed #ccc;*/
  padding: 10px;
  margin-bottom: 5px;
  list-style-type: none;
  font-size: 110%;
}

.domain_list {
  list-style-type: none;
}

.domain_title {
  position: relative;
  list-style-type: none;
}

.attribute_title {
  list-style-type: none;
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 5px;
}

.attribute_title:checkbox {
  top: 0;
}

.attribute_selected {
  color: #000;
}

.attribute_selected:before {
  /*content: '\2713';*/
  font-weight: bold;
  margin-right: 10px;
}

.attribute_not_selected:before {
  content: ' ';
  margin-right: 22px;
}

.attribute_label {
  /* font-size: large; */
  list-style-type: none;
  margin-bottom: 7px;
  font-weight: normal;
  cursor: pointer;
}

.attribute_type {
  /* font-size: large; */
  list-style-type: none;
  margin-top: 20px;
  font-weight: 600;
}

.attribute_section {
  margin-left: 5%;
}

.attribute_type li {
  font-weight: normal;
}

.competency_section {
  /*  border: 1px outset #ccc;
*/
  margin-top: 30px;
  padding: 10px;
}

.edit-inline-controls {
  font-size: 12px;
}

.form_intro {
  font-size: large;
  font-weight: lighter;
  margin-top: 20px;
  margin-bottom: 20px;
  /*left: 40px;*/
  position: relative;
}

.icon-custom {
  margin-right: 10px;
  margin-bottom: 10px;
}

.button {
  font-size: larger;
}

.profile_navigation {
  display: inline;
  margin-left: 10px;
}

.legend {
  font-size: 20px;
  margin: 20px;
  display: table-cell;
  padding: 20px;
  text-align: center;
}

.legend_number {
  background-color: #cbd0df;
  border-radius: 50%;
  padding: 15px;
  font-weight: 600;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.legend_container {
  width: 100%;
  padding: 20px;
  border: 2px solid #000;
  background-color: #f1f1f1;
  display: table-row;
  text-align: center;
}

.Draft {
  background-image: url('./app/containers/watermark.jpg');
  background-size: 15%;
}

.profile_legend {
  display: table-cell;
  padding: 20px;
}

#profile1_legend:before {
  content: ' ';
  border: 1px solid #ccc;
  background-color: #1f77b4;
  padding: 0 5px 0 15px;
}

#profile2_legend:before {
  content: ' ';
  border: 1px solid #ccc;
  background-color: #ff7f0e;
  padding: 0 5px 0 15px;
}

.profileMeta {
  display: table-row;
  margin-bottom: 20px;
}

.profileMeta div {
  display: table-cell;
  padding: 10px;
  margin: 10px;
}

.legend-inline {
  list-style-type: none;
  display: inline-flex;
  /*float: right;
  position: absolute;
  bottom: 0;*/
}

.legend-inline li {
  margin-right: 30px;
}

.legend-inline-wrapper {
  border: 1px dashed #ccc;
  text-align: center;
}

.action-buttons {
  margin: 5px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 5px 0 rgba(0, 0, 0, 0.19) !important;
}

.compare_checkbox {
  display: flex;
}

.compare_checkbox label {
  /*padding: 5px;*/
  width: 150px;
}

.ChartRow-barLabel {
  max-width: 300px;
}

.ButterflyChart {
  width: 100%;
}

.butterflyRow {
  margin-bottom: 15px;
}

.fillerbg {
  background-color: #ccc;
}

.fillerLeft {
  background-color: rgb(233, 180, 0);
  width: 50%;
  margin-left: 50%;
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
}

.fillerRight {
  background-color: rgb(233, 180, 0);
  /*width: 50%;*/
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
}

table tbody tr:nth-child(even) {
  background-color: #fff;
}

@media print {
  footer {
    display: none;
  }

  .vf-button {
    display: none;
  }

  .submit_fixed {
    display: none;
  }

  nav {
    display: none;
  }

  .vf-content h2:not([class*='vf-']) {
    font-size: 20px;
  }

  .vf-content h3:not([class*='vf-']) {
    font-size: 18px;
  }

  .masthead-inner {
    display: none;
  }

  #main-content-area {
    margin-top: 0px;
  }

  .profile_navigation {
    display: none;
  }

  /* #main-content-area:before {
    content: 'Competency Mapper';
    font-size: 40px;
    position: absolute;
    top: 50px;
  } */

  .button {
    display: none;
  }

  .vf-badge--tertiary {
    background: #777 !important;
    color: #fefefe !important;
  }

  /* filters and fills */
  .fillerbg {
    background-color: #ccc !important;
    width: 100px !important;
    float: right;
  }

  .fillerRight {
    background-color: rgb(233, 180, 0) !important;
  }

  .fillerLeft {
    background-color: rgb(233, 180, 0) !important;
  }

  /* .vf-grid__col--span-3 {
    float: left;
  }

  .vf-grid__col-5 {
    float: left;
  } */

  .vf-grid__col--span-1 {
    float: right;
  }

  .competency_title {
    font-size: 14px;
  }

  .vf-text-heading--5 {
    font-weight: normal;
  }

  .vf-grid__col-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .vf-grid__col-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  .vf-grid {
    grid-auto-flow: column;
  }
}

.tooltip-custom p {
  font-size: 18px !important;
  color: #fff !important;
}

.my-profile-card {
  background-color: #f1f1f1;
  color: #333;
}

.ref-profile-card {
  background-color: #ccc;
}

.cm_badge {
  background-color: #fab700;
  padding: 5px;
  color: #333;
  left: 25%;
  position: relative;
}

.userprofile-table {
  font-size: 20px;
}

.userprofile-table tr td {
  padding: 5px;
  margin: 5px;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  background: #b2dbbf;
  max-width: 600px;
  width: 100%;
}

.domain-row {
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc;
}

.addButton {
  color: #193f90;
  font-weight: normal;
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
}

.addButton:visited {
  color: #193f90;
}

.edit-indicator {
  display: none;
  color: #193f90;
  font-size: 16px;
}

.edit-indicator button {
  color: #193f90;
  /*  margin-left: 10px;*/
}

.domain_title:hover > .column .edit-indicator {
  display: inline;
}

.competency_title:hover > h3 .edit-indicator {
  display: inline;
}

.attribute_title:hover .edit-indicator {
  display: inline;
}

.domain_title {
  margin-bottom: 25px;
}

.collapsible-arrows {
  position: relative;
  left: -40px;
  top: -30px;
  display: inline;
}

code {
  color: #fff;
}

.ch_tabs__list ul {
  padding: 0px;
  width: 100%;
  /* border-bottom: 1px solid #000 !important; */
  margin-bottom: 2rem;
}

.ch_tabs__list .vf-tabs__item {
  margin-left: 0;
}

/* .ch_tabs__list a {
  text-decoration: none;
  background: none ! important;
} */

.ch_tabs__list ul li {
  border: none;
  margin-bottom: 0 !important;
}

.ch_tabs__list .vf-tabs__link.is-active {
  border: none !important;
  font-weight: 500;
}

.ch_tabs__list .vf-tabs__link:hover {
  color: #000;
}

.ch_tabs__list .react-tabs__tab--selected {
  border-bottom: 6px solid #307fe2 !important;
}

.ch_link {
  border: none;
  background-color: #fff;
  cursor: pointer;
}

/* Over writing not recommended*/
.react-responsive-modal-overlay {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 11000 !important;
}

.ReactModal__Content {
  height: 300px !important;
}
.customTabLinks {
  text-decoration: none !important;
  color: black !important;
}
.suggestions_results {
  position: absolute;
  display: block;
  background-color: #fff;
  color: #222;
  border: 1px solid #aaa;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 20;
  min-width: 300px;
  width: 50%;
  max-width: 500px;
}

.suggestions_results li {
  list-style-type: none;
  cursor: pointer;
  padding: 0.3em 1em 0.3em 0.5em;
  display: block;
  margin: 0;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #d0d0ce;
}
