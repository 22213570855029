.icon-left-spacer {
  margin-left: 10px;
}

.edit-inline-container {
  position: relative;
}

.inner-input {
  margin: 0;
}

.edit-inline-controls {
  background: white;
  position: absolute;
  right: 0;
}
